<template>
  <div>
    Loading ...
    <b-modal
      id="modal-validation-add-order"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <b-row class="p-1 justify-content-center">
        <div>
          <h4 class="text-black">
            <strong>
              Tambah Order
            </strong>
          </h4>
        </div>
      </b-row>
      <b-row>
        <div class="text-center text-black ">
          Sepertinya orderan yang kamu buat cukup banyak. Yuk top up saldo Rp 30.000 terlebih dahulu untuk jaga-jaga agar
          saldo kamu tidak minus ketika terjadi orderan retur. Tenang aja, saldomu nantinya bakal bisa ditarik full kok
          jika transaksi terkirim semua
        </div>
      </b-row>
      <b-row class="justify-content-between mx-1 mt-3 mb-2">
        <b-button
          variant="outline-primary"
          class="w-48"
          @click="$router.push({ name: 'dashboard-komship' })"
        >
          Kembali
        </b-button>
        <b-button
          variant="primary"
          class="w-48"
          @click="$router.push({ name: 'dashboard-komship', params: { topup: true } })"
        >
          Top Up
        </b-button>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('dashboard', ['profile']),
  },
  async mounted() {
    if (this.profile.partner_is_mass_order) {
      this.$router.push('/method-order')
    } else {
      this.getLimitOrders()
    }
  },
  methods: {
    async getLimitOrders() {
      await this.$http_komship.get('/v1/user/limit-order')
        .then(response => {
          if (!response.data.data.is_lock) {
            this.$router.push({ path: '/add-order' })
          } else {
            this.$bvModal.show('modal-validation-add-order')
          }
        })
    },
  },
}
</script>
